/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';

@import '~loaders.css/loaders.css';

@import '~ngx-toastr/toastr.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~summernote/dist/summernote.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//== NgSelect
@import "~@ng-select/ng-select/themes/default.theme.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
